import React, { useEffect, useState } from 'react'
import { Col, Container, Offcanvas, Row } from 'react-bootstrap'
import Sidenav from '../Componet/Sidenav/Sidenav'
import menu from '../Photo/menu.svg'
import profile from '../Photo/profile.svg'
import moment from 'moment'
import { API_URLS, serviceUrl } from '../API/Api'
import { STORAGE_KEY } from '../constant/common'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'
import Input from '../Componet/InputFild/Input'
import Table from '../Componet/DataTable/Table'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackBtn from '../Componet/Button/BackBtn'

const { Blood_Report } = API_URLS

export default function BloodReport() {

            async function GetBloodReport(credentials) {
                        return fetch(Blood_Report.SelectBloodReport, {
                                    method: 'POST',
                                    headers: {
                                                'Content-Type': 'application/json',
                                                Accept: 'application/json',
                                                Authorization: `Bearer ${added_by}`
                                    },
                                    body: JSON.stringify(credentials)
                        }).then(res => res.json())
            }


            const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)
            const API_KEY = serviceUrl
            const [searchFilter, setSearchFilter] = useState('')
            const [bloodreportList, setbloodReportList] = useState([])
            const [heightOffSet, setHeight] = useState(0)
            const [headerShow, setHeaderShow] = useState(true)
            const [show, setShow] = useState(false)
            const [isLoading, setIsLoading] = useState(false)

            const navigate = useNavigate()
            const logOut = () => {
                        navigate('/')
            }
            const fetchReportData = async () => {
                        try {
                                    const data = await GetBloodReport()
                                    setbloodReportList(data.data)
                                    console.log("data ", JSON.stringify(data))
                        } catch (error) {
                                    console.log(error)
                        }
            }

            const column = [
                        {
                                    name: 'No.',
                                    cell: (e, index) => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {index + 1} 
                                                </div>
                                    ),
                                    width: '50px',
                                    sortable: true
                        }
                        ,
                        {
                                    name: 'Tag Id',
                                    selector: 'tag_id',
                                    cell: e => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {e['tag_id']}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
                        {
                                    name: 'Date',
                                    selector: 'date',
                                    cell: e => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {moment(e['date'], 'DD-MM-YYYY').format('DD-MM-YYYY')}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
                        {
                                    name: 'Test Type',
                                    selector: 'test_type',
                                    cell: e => (
                                                <div className='text-right px-1' style={{ paddingRight: '5px' }}>
                                                            {e['test_type']}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
                        {
                                    name: 'White RGB',
                                    selector: 'white_rgb',
                                    cell: e => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {e['white_rgb']}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
                        {
                                    name: 'Sample RGB',
                                    selector: 'sample_rgb',
                                    cell: e => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {e['sample_rgb']}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
                        {
                                    name: 'Corrected RGB',
                                    selector: 'corrected_rgb',
                                    cell: e => (
                                                <div className='ms-auto' style={{ paddingRight: '5px' }}>
                                                            {e['corrected_rgb']}
                                                </div>
                                    ),
                                    width: '250px',
                                    sortable: true
                        },
            ]

            useEffect(() => {
                        fetchReportData()
            }, [])

            return (
                        <>
                                    <Container fluid>
                                                <Row>
                                                            {headerShow && (
                                                                        <Col lg='2' className='hideNavbar'>
                                                                                    {' '}
                                                                                    <Sidenav />
                                                                        </Col>
                                                            )}
                                                            <Col className='header' lg={headerShow ? '10' : '12'}>
                                                                        <Container fluid>
                                                                                    <Row className='pt-1 sub-header align-items-center justify-content-between'>
                                                                                                <Col
                                                                                                            md='10'
                                                                                                            sm='10'
                                                                                                            xs='10'
                                                                                                            className='py-2 d-flex align-items-center'
                                                                                                >
                                                                                                            <button style={{ border: 'none' }} className='hideNavbar1'>
                                                                                                                        <img src={menu} width={20} />
                                                                                                            </button>
                                                                                                            <button style={{ border: 'none' }} className='hide_Navbar'>
                                                                                                                        <img src={menu} width={20} />
                                                                                                            </button>
                                                                                                            <h5 className='ms-4 mt-2'>Blood Report</h5>
                                                                                                </Col>
                                                                                                <Col
                                                                                                            md='1'
                                                                                                            sm='1'
                                                                                                            xs='10'
                                                                                                            className='py-2 d-flex align-items-center text-end ms-auto'
                                                                                                >
                                                                                                            <BackBtn
                                                                                                                        style={{ border: 'none' }}
                                                                                                                        className='hideNavbar1'
                                                                                                                        lable='Back'
                                                                                                                        onClick={() => {
                                                                                                                                    navigate('/')
                                                                                                                        }}
                                                                                                            />
                                                                                                </Col>
                                                                                                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                                                                                                            <img src={profile} width={40} />
                                                                                                            <div className='dropdpwn_Open'>
                                                                                                                        <Row className='text-center align-items-center'>
                                                                                                                                    <Col xs='12' className='menuHover mb-3'>
                                                                                                                                                <Row>
                                                                                                                                                            <Col className='' xs='3'>
                                                                                                                                                                        <span className=''>
                                                                                                                                                                                    <MdOutlineVpnKey size={25} />
                                                                                                                                                                        </span>
                                                                                                                                                            </Col>
                                                                                                                                                            <Col className='text-start' xs='9'>
                                                                                                                                                                        Change Password
                                                                                                                                                            </Col>
                                                                                                                                                </Row>
                                                                                                                                    </Col>
                                                                                                                                    <Col xs='12' className='menuHover' onClick={logOut}>
                                                                                                                                                <Row>
                                                                                                                                                            <Col className='' xs='3'>
                                                                                                                                                                        <span className=''>
                                                                                                                                                                                    <GoSignOut size={25} />
                                                                                                                                                                        </span>
                                                                                                                                                            </Col>
                                                                                                                                                            <Col className='text-start' xs='9'>
                                                                                                                                                                        Sign Out
                                                                                                                                                            </Col>
                                                                                                                                                </Row>
                                                                                                                                    </Col>
                                                                                                                        </Row>
                                                                                                            </div>
                                                                                                </Col>
                                                                                    </Row>
                                                                                    <Row className='align-items-end div-2 mt-2' id='heightOffsetStock'>
                                                                                                <Col
                                                                                                            md='3'
                                                                                                            xxl='2'
                                                                                                            xl='2'
                                                                                                            xs='6'
                                                                                                            className='align-items-center ms-auto'
                                                                                                >
                                                                                                            <Input label='Search' />
                                                                                                </Col>
                                                                                    </Row>

                                                                                    <Row className='mt-2' id='heightOffsetStock'>
                                                                                                {bloodreportList?.length > 0 ? (
                                                                                                            <div className='blue_bg DataTableBorder mt-1'>
                                                                                                                        <Table
                                                                                                                                    columns={column}
                                                                                                                                    data={searchFilter ? searchFilter : bloodreportList}
                                                                                                                                    height={`calc(100vh - (${heightOffSet}px + 210px))`}
                                                                                                                                    pagination
                                                                                                                        />
                                                                                                            </div>
                                                                                                ) : (
                                                                                                            <Row>
                                                                                                                        <Col>
                                                                                                                                    <div className='NoTableBorder ms-2'>
                                                                                                                                                <div className='d-flex tabledata colmBreed'>
                                                                                                                                                            {column.map(i => {
                                                                                                                                                                        return (
                                                                                                                                                                                    <Col
                                                                                                                                                                                                key={i.name}
                                                                                                                                                                                                className={`blankColumns ${i.name.replace(
                                                                                                                                                                                                            ' ',
                                                                                                                                                                                                            '_'
                                                                                                                                                                                                )}`}
                                                                                                                                                                                                style={{ backgroundColor: 'rgb(169,169,169)' }}
                                                                                                                                                                                                height={`calc(100vh - (${heightOffSet}px + 20px))`}
                                                                                                                                                                                    >
                                                                                                                                                                                                {i.name}
                                                                                                                                                                                    </Col>
                                                                                                                                                                        )
                                                                                                                                                            })}
                                                                                                                                                </div>
                                                                                                                                                <h6 className='text-center align-items-center mt-5 noData ms-5'>
                                                                                                                                                            No Data
                                                                                                                                                </h6>
                                                                                                                                    </div>
                                                                                                                        </Col>
                                                                                                            </Row>
                                                                                                )}
                                                                                    </Row>
                                                                        </Container>
                                                            </Col>
                                                </Row>
                                    </Container>
                                    {show && (
                                                <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
                                                            <Container>
                                                                        <Row>
                                                                                    <Col>
                                                                                                <Sidenav />
                                                                                    </Col>
                                                                        </Row>
                                                            </Container>
                                                </Offcanvas>
                                    )}

                                    {isLoading ? (
                                                <div className='circleText'>
                                                            <div className='progressText'>{progressData}</div>
                                                            <div className=''>
                                                                        <span className='loader-circle-13'></span>
                                                            </div>
                                                </div>
                                    ) : (
                                                false
                                    )}
                                    <ToastContainer
                                                position='top-center'
                                                autoClose={2000}
                                                hideProgressBar={true}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme='light'
                                                style={{ fontSize: '14px' }}
                                                limit='1'
                                    />
                        </>
            )
}
